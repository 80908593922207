@import 'co-variables';

/* ==============================================================
Background colors
===============================================================*/
.bg-f8 {
    background-color: #f8f8f8 !important;
}

.bg-f5 {
    background-color: #f5f5f5 !important;
}

.bg-25 {
    background-color: #25252c;
}

.bg-4d {
    background-color: $co-body-color;
    opacity: 0.3;
}

/* ==============================================================
Positioning
===============================================================*/
.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.top-0 {
    top: 0 !important;
}

.top-12 {
    top: 0.75rem;
}

.bot-0 {
    bottom: 0;
}

.bot-1 {
    bottom: 0.25rem;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.right-2 {
    right: 0.5rem;
}

.center-absolute {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.center-y {
    top: 50%;
    transform: translateY(-50%);
}

.z-0 {
    z-index: 0 !important;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.z-3 {
    z-index: 3;
}

.scroll-gutter-stable {
    scrollbar-gutter: stable;
}

/* ==============================================================
Font Size
===============================================================*/

.fs-58 {
    font-size: 3.625rem;
}

.fs-42 {
    font-size: 2.625rem;
}

.font-18 {
    font-size: 1.125rem;
}

.font-16 {
    font-size: 1rem;
}

.font-14 {
    font-size: 0.875rem;
}

.font-12 {
    font-size: 0.75rem;
}

.font-10 {
    font-size: 0.625rem;
}

.min-height-18 {
    min-height: calc(1.125rem * var(--bs-body-line-height));
}

.min-height-16 {
    min-height: calc(1rem * var(--bs-body-line-height));
}

.min-height-14 {
    min-height: calc(0.875rem * var(--bs-body-line-height));
}

.min-height-12 {
    min-height: calc(0.75rem * var(--bs-body-line-height));
}

.size-3rem {
    width: 3rem;
    height: 3rem;
}

.size-13px {
    width: 13px;
    height: 13px;
}

/* ==============================================================
Opacity
===============================================================*/
.opacity-0 {
    opacity: 0;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-8 {
    opacity: 0.8;
}

/* ==============================================================
General utilities
===============================================================*/
.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.w-80 {
    width: 80% !important;
}

.w-fit {
    width: fit-content;
}

.ar-2 {
    aspect-ratio: 2;
}

.pointer {
    cursor: pointer !important;
}

.pointer-ev-none {
    pointer-events: none;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.gutter-stable {
    scrollbar-gutter: stable;
}

overflow-y-hidden {
    overflow-y: hidden;
}

.white-space-nowrap {
    white-space: nowrap;
}

.user-select-none {
    user-select: none;
}

.rotate-180 {
    transform: rotate(180deg);
}

/* ==========================================================================
Shadow
========================================================================== */
.drop-shadow {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

//drop-shadow only on right side
.clip-shadow-right {
    clip-path: inset(0 -5px 0 0);
}

.dropdown-shadow {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* ==========================================================================
Padding/margin
========================================================================== */
.p-12 {
    padding: 0.75rem !important;
}

.ps-12,
.px-12 {
    padding-left: 0.75rem !important;
}

.pe-12,
.px-12 {
    padding-right: 0.75rem !important;
}

.pt-12,
.py-12 {
    padding-top: 0.75rem !important;
}

.pb-12,
.py-12 {
    padding-bottom: 0.75rem !important;
}

.m-12 {
    margin: 0.75rem !important;
}

.ms-12,
.mx-12 {
    margin-left: 0.75rem !important;
}

.me-12,
.mx-12 {
    margin-right: 0.75rem !important;
}

.mt-12,
.my-12 {
    margin-top: 0.75rem !important;
}

.mb-12,
.my-12 {
    margin-bottom: 0.75rem !important;
}

.gap-12 {
    gap: 0.75rem;
}

.mr13 {
    margin-right: 0.8125rem !important;
}

/* ==========================================================================
Width/height
========================================================================== */
.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.w-200px {
    width: 12.5rem;
}

.h-77px {
    height: 4.8125rem;
}

/* ==========================================================================
Border
========================================================================== */
.br-bot {
    border-bottom: 1px solid #5454541a;
}

/* ==========================================================================
Display
========================================================================== */
.d-grid {
    display: grid;
}

.place-items-center {
    place-items: center;
}

/* ==========================================================================
Text
========================================================================== */

.text-25 {
    color: #25252c;
}

.text-body {
    color: $co-body-color;
}

.text-gray {
    color: $co-body-color;
    opacity: 0.6;
}

.text-4d {
    color: $co-body-color;
    opacity: 0.3;
}

/* ==========================================================================
SVG
========================================================================== */

.fill-default {
    fill: $co-body-color;
}

.fill-primary {
    fill: $co-primary;
}

.fill-gray {
    fill: $co-body-color;
    opacity: 0.6;
}

.fill-4d {
    fill: $co-body-color;
    opacity: 0.3;
}

.fill-white {
    fill: #ffffff;
}

.fill-disabled {
    fill: $co-body-color;
    opacity: 0.3;
}
